/* General styles for a clean and responsive layout */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
}

.container {
  margin: 0 auto;
  padding: 20px;
}

/* Styles for the form */
.form-group {
  margin-bottom: 15px;
}

.form-label {
  display: block;
  margin-bottom: 5px;
}

.form-control {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.btn-primary,
.btn-secondary {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
}

.btn-secondary {
  background-color: #ccc;
  color: #333;
  margin-left: 10px;
}

/* Styles for the table */
.table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
  margin-top: 20px;
}

.table th,
.table td {
  padding: 10px;
  border: 1px solid #ddd;
}

.table thead th {
  background-color: #f2f2f2;
}

/* Styles for the actions buttons (icons can be added using Font Awesome or other libraries) */
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.actions button {
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.actions button:first-child {
  background-color: #ffc107;
  color: #333;
}

.actions button:last-child {
  background-color: #dc3545;
  color: #fff;
}

.form-field {
    max-width: 80%; /* Prevents the field from exceeding the screen width */
    overflow-wrap: break-word; /* Allows long words to wrap and not overflow */
    height: auto; /* Adjusts height based on content */
    min-height: 40px; /* Minimum height to start with, adjust as needed */
  }

/* Responsiveness for smaller screens */
@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .form-control {
    font-size: 14px;
  }

  .btn-primary,
  .btn-secondary {
    font-size: 14px;
  }

  .table {
    font-size: 14px;
  }
}

.column-max-width {
    max-width: 40% !important;
  }

  .text-overflow-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .bordered-row {
    border: 1px solid #ddd; /* Darker gray border */
    margin-bottom: 5px; /* Add some space between rows */
  }
  
  .bordered-row:nth-child(odd) {
    background-color: #e0f2ff; /* Light blue background for odd rows */
  }
  
  .bordered-col {
    border-right: 1px solid #dee2e6; /* Add border to the right of each column */
  }
  
  /* Optional: Remove border for the last column (adjust based on your structure) */
  .bordered-col:last-child {
    border-right: none;
  }
  
  
  /* Remove right border for the last column for aesthetic purposes
  .bordered-col:last-child {
    border-right: none;
  } */