.Card {
    border: 1px solid #ddd !important; /* Add a thin border */
    border-radius: 5px !important; /* Add rounded corners */
    /* background-color: #f5f5f5; Set a light background color */
    /* margin-bottom: 20px; Add some spacing between cards */
}

.card-post-title{
    padding: 5px;
    font-size:  30px !important;
    font-weight: bold !important;
    color: #c9631a !important;
  }
  
  .card-post-title a{
    color: #c9631a !important;
    text-decoration: none !important;
  }

  .card-post-text{
    padding: 5px;
    font-size: 20px;
  }

  .card-post-text p{
    padding: 5px ;
    font-size: 20px;
    text-align: left;
  }

  .card-post-text ul{
    padding: 5px !important;
    font-size: 20px !important;
    text-align: left !important;
    margin-left: 10px !important;
  }

  .card-post-text li{
    padding: 5px !important;
    font-size: 20px !important;
    text-align: left !important;
  }
  
  /* .card-post-text h1{
    padding: 5px;
    font-size:  30px;
    font-weight: bold;
    color: #000;
  } */
  
  @media screen and (max-width: 768px) {
    .card-post-title{
        padding: 5px;
        font-size:  20px !important;
        font-weight: bold !important;
        color: #c9631a !important;
      }
      
      .card-post-title a{
        color: #c9631a !important;
        text-decoration: none !important;
      }
      .card-post-text {
        padding: 5px !important;
        font-size: 20px !important;
        color: #000 !important;
        text-align: left !important;
      }
      .card-post-text ul{
        padding: 5px !important;
        font-size: 20px !important;
        color: #000 !important;
        text-align: left !important;
        margin-left: 5px !important;
      }
      .card-post-text li{
        padding: 5px !important;
        font-size: 20px !important;
        color: #000 !important;
        text-align: left !important;
      }

    .card-post-text p{
      padding: 5px !important;
      font-size: 20px !important;
      color: #000 !important;
      text-align: left !important;
    }
    /* .card-post-text h1{
      padding: 5px;
      font-size:  20px;
      color: #000;
    } */
    
  }