/* AboutUs.css */

.container {
    margin-top: 2rem;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .button-container {
    text-align: center;
    margin-bottom: 1.5rem;
  }
  
  button {
    margin: 0.5rem;
  }
  
  .content-section {
    background-color: #f8f9fa;
    padding: 2rem;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    line-height: 1.6;
  }
  
  .content-section p {
    margin-bottom: 1rem;
  }
  
  .content-section h2 {
    margin-top: 1.5rem;
    color: #007bff;
  }
  
  .content-section ol, .content-section ul {
    margin-left: 1.5rem;
  }
  
  .content-section li {
    margin-bottom: 0.5rem;
  }
  
  .button-container .active {
    color: #007bff; /* Active button color */
    font-weight: bold;
  }
  
  /* You can also add styles for hover, focus, etc., as needed */
  .button-container .active:hover {
    color: #0056b3;
  }