@font-face {
  font-family: "Bebas Neue";
  src: url(../fonts/BebasNeue-Regular.ttf) format("woff2"), url(../fonts/BebasNeue-Regular.ttf) format("woff");
  font-weight: 400;
  font-style: normal;
}

.navbar-nav {
  float: right !important;
}

.navbar-nav a {
  /* Add your link styles here */
  margin-left: 2rem !important;
  font-size: large;
  font-family: "Bebas Neue" !important;
  letter-spacing: 2px;
}

.na-logo {
  color: #933e01 !important;
  font-weight: bolder !important;
  font-size: 120px !important;
  font-weight: 850 !important;
  font-family: "Bebas Neue" !important;
  letter-spacing: 3px;
  /* margin-top: 90px !important; */
  /* margin-left: -60px; */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.na-logo-png {
  width: 440px;
  height: 360px;
}

.row-logo {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-top: -60px !important;
}

.navbar-nav a {
    color: #933e01 !important;
    font-weight: bolder !important;
    font-size: 30px !important;
    font-weight: 225 !important;
    font-family: "Bebas Neue" !important;
    letter-spacing: 2px;
  }
  
  .navbar-nav a:after {
    /* content: "\f107"; */
    font-weight: bolder !important;
    font-size: 30px !important;
    font-weight: 225 !important;
    font-family: "Bebas Neue" !important;
    letter-spacing: 2px;
    text-decoration: inherit;
    color: rgba(0, 0, 0, 0.5);
  }

/* #fh5co-primary-menu > li > ul li.active > a {
  color: #933e01 !important;
  font-weight: bolder !important;
  font-size: 60px !important;
  font-weight: 425 !important;
  font-family: "Bebas Neue" !important;
  letter-spacing: 2px;
}

#fh5co-primary-menu > li > .sf-with-ul:after {
  content: "\f107";
  font-weight: bolder !important;
  font-size: 60px !important;
  font-weight: 425 !important;
  font-family: "Bebas Neue" !important;
  letter-spacing: 2px;
  text-decoration: inherit;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  margin-left: 5px;
} */

@media screen and (max-width: 768px) {
  .navbar-nav {
    float: none !important;
  }
  .navbar-nav a {
    margin-left: 40px !important;
  }
  .na-logo {
    font-size: 30px !important;
  }
  .na-logo-png {
    width: 100px;
    height: 80px;
  }
  .row-logo {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0px !important;
  }
}
